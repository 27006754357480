import Wrapper from "../Wrapper";
import Image1 from "../../images/steps/s1.jpg"
import Image2A from "../../images/steps/s2a.jpg"
import Image2 from "../../images/steps/s2.jpg"
import Image3 from "../../images/steps/s3.jpg"
import Image4 from "../../images/steps/s4.jpg"
import Image5 from "../../images/steps/s5.jpg"
import Image6 from "../../images/steps/s6.jpg"
import Image7 from "../../images/steps/s7.jpg"
import Image8 from "../../images/steps/s8.jpg"
import Image9 from "../../images/steps/s9.jpg"
import Image10 from "../../images/steps/s10.jpg"
import Image11 from "../../images/steps/s11.jpg"
import Image12 from "../../images/steps/s12.jpg"
import Image13 from "../../images/steps/s13.jpg"
import Image14 from "../../images/steps/s14.jpg"
import Image14a from "../../images/steps/s13a.jpg"
const Step = (): JSX.Element => {
    return (
        <>
            <Wrapper pageTitle={'How to book an appointment?'} pageDes={`Following below 6 eas`}>

                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 1</div>
                                <p>“CHOOSE SERVICE” and Click “Read more”</p>
                            </div>
                            <div className="col-md-8"><img src={Image1} alt="" title="" /></div>
                        </div>
                    </div>
                </div>

                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image2A} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 2</div>
                                <p>Scroll down to “BOOK APPOINTMENT” and click.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 3</div>
                                <p>If  first time registering : go to “NEW REGISTER” (Step 4), Else log in using  either “username”, “email”, “Phone number”, then enter “password”. Then go to “STEP 5”.If password forgotten - click “forgot password” for pass word retreive.</p>
                            </div>
                            <div className="col-md-8"><img src={Image3} alt="" title="" /></div>
                        </div>
                    </div>
                </div>

                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image2} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">

                                <div className="stepTitle">Step 4</div>
                                <p>FOR NEW REGISTER , Fill in the form. , “*” are mandatory information and press “register”. then go to “STEP 5”</p>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 5</div>
                                <p>Select the available date and available time slot.</p>
                            </div>
                            <div className="col-md-8"><img src={Image5} alt="" title="" /></div>
                        </div>
                    </div>
                </div>

                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image7} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 6</div>
                                <p>Select the member for whom appointment is needed. If “new member”, the click “ADD MEMBER”, enter details, then “select member”. Click “next</p>
                            </div>

                        </div>
                    </div>
                </div>





                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 7</div>
                                <p>Enter “current problem” , this is mandatory . Upload any recent investigations or reports or other consultation. and click “next”.</p>
                            </div>
                            <div className="col-md-8"><img src={Image9} alt="" title="" /></div>
                        </div>
                    </div>
                </div>




                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image12} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 8</div>
                                <p>First timmers - please fill form and enter as much information as possible, and click “next”. If you have already done it - use this form to add/delete any previous information and then click “next"</p>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 9</div>
                                <p>GO TO PAYMENT GATEWAY AND Follow the instructions.</p>
                            </div>
                            <div className="col-md-8"><img src={Image14} alt="" title="" /></div>

                        </div>
                    </div>
                </div>

                <div className="flowSteps">
                    <div className="container">
                        <div className="row align-content-center justify-content-center align-items-center">
                            <div className="col-md-8"><img src={Image14a} alt="" title="" /></div>
                            <div className="col-md-4 text-bg">
                                <div className="stepTitle">Step 10</div>
                                <p>Come to your home screen to “Dashboard” . your appointment confirmation wil be there. Also you wil receive email, regarding appointments.</p>
                            </div>

                        </div>
                    </div>
                </div>



            </Wrapper>
        </>
    );
};
export default Step;
