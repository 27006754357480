/* eslint-disable no-empty-pattern */
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ErrorBoundary from "./ErrorBoundary";
interface AlertProps {
  children?: any;
  pageTitle?: string;
  breadcrumbList?: any;
  pageDes?: string;
}
const Wrapper = ({
  children,
  pageTitle,
  breadcrumbList,
  pageDes
}: AlertProps): JSX.Element => {
  return (
    <>
      <div className="containerWrapper">
        <Header />
        <ErrorBoundary>
          <div className="pageWrapper">
            {pageTitle &&
              <div className="banner banner2">
                <div className="banner_text">
                  <h3 className="changeCase">{pageTitle}</h3>
                  <p>{pageDes}</p>
                </div>
              </div>
            }
            {/* <div className="row">
          <div className="col-md-6">
            <h2 className="page-title">{pageTitle}</h2>
          </div>
          <div className="col-md-6 text-right">
            <ul className="breadcrumb1">
              {breadcrumbList.map((item: any) => {
                return (
                  <li>
                    <NavLink to={item.link}>{item.title}</NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div> */}
            {children}
          </div>
        </ErrorBoundary>
        <Footer />
        <div className="floatingMenu">
          <NavLink to="/book-appointment-steps">Need help in registration</NavLink>
          </div>
      </div>
    </>
  );
};
export default Wrapper;
